import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { ServiceService } from 'src/app/servies/service.service';
import { NgModule } from '@angular/core';
import { HtmlParser, NONE_TYPE } from '@angular/compiler';
import { DatePipe, Location } from '@angular/common';
import { Storage } from '@ionic/storage-angular';
import { AlertController, MenuController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
 

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
})
export class MasterComponent implements OnInit {

  name = 'Angular ';
  private _MainMenu = [];
  footer = false;
  empCodeName: any;
  currentuser: any;
  menuopen = false;
  openfab: boolean;
  MainMenu: any;
  empimg: string;
  empcode: string;
  mainheader: boolean;
  opensettings: boolean;
  Ischeckin_Enable: boolean;
  ischeckined: boolean = false;
  Isempinlocations: any;
  timer: NodeJS.Timeout;
  time: string;
  tClass: any;
  todayDate: string; myChoice = "one"
  lati: number;
  long: number;
 

  constructor(
    private router: Router,
    private api: ServiceService,
    private storage: Storage,
    private menu: MenuController,private http:HttpClient,public alertCtrl:AlertController, 
    private datePipe:DatePipe) {
    // this.currentuser= this.api.currentuser;
    // console.log(this.currentuser);
    router.events.subscribe((event: Event) => {
      this.api.opensettings = false;
      if (this.api.EmpCode == "1501") {
        this.api.opensettings = true;
        // console.log(this.api.EmpCode);
      }

      let Ispage: boolean = false;
      if (event instanceof NavigationEnd) {
      // this.router.navigateByUrl('/eprofile');
        if (event.urlAfterRedirects === '' || event.urlAfterRedirects === '/login') {
          this.footer = false;
          this.mainheader = false;
          this.menu.enable(false);
          } else {
          this.footer = true;
          this.mainheader = true;
          this.empCodeName = this.api.EmpCodeName;
          this.menu.enable(true);
        }

        // this.api.url=event.urlAfterRedirects;
        // this.api.url=false;
        // console.log('hisdfdsfsfdsf');
        // for (let i = 0; i < this._MainMenu.length; i++) {

        //   console.log(event.urlAfterRedirects);

        //   if(event.urlAfterRedirects == '/login' || event.urlAfterRedirects == '/home'){
        //       this.api.url=true;
        //       break;
        //   }
        //   else if (this.api.EmpCode == "1501" && event.urlAfterRedirects == '/settings' ) {
        //     this.api.url=true;
        //     break;
        //   }
        //  else if (this.MainMenu[i]["NavigateUrl"] == event.urlAfterRedirects) {
        //     this.api.url=true;
        //     break;
        //   }
        // }
        // console.log(this.api.url);

        // if (!Ispage && event.urlAfterRedirects != '/login') {
        //   console.log(event.urlAfterRedirects);
        //   if (event.urlAfterRedirects == '/settings' && this.api.EmpCode == "1501") {
           
        //   }
        //   else {
        //     if (event.urlAfterRedirects != '/home')
        //        this.api.presentToast("Don't have Permission..!");
              
        //  // this.router.navigateByUrl('/home');
        //   }
        // }
      }
    });
  }

  async ngOnInit() {

    this.currentuser = this.api.currentuser;
    const uname = await this.storage.get('username');
    const pass = await this.storage.get('password');
    const eName = await this.storage.get('EmpCodeName');
    const Usertype = await this.storage.get('UserType');
    const UserDesig = await this.storage.get('UserDesig');
    // const IsChekin = await this.storage.get('IsChekin');
    this.empcode = uname;
    this.empimg = "./assets/DBS/Emp_ph/Small/" + this.empcode + ".jpg";
    this.empCodeName = eName;
    this.api.UserType = Usertype;
    this.api.UserDesig  = UserDesig;
    this.api.EmpName = eName;

    this.api.opensettings = false;
    if (uname == "1501") {
      this.api.opensettings = true;
    }

    this.Load_Menu(uname, eName);
    this.checktimeReport();
    this.Checkin_Status(); 
  }

  ionViewWillEnter() {
    let currentdate = this.datePipe.transform(new Date());
    this.todayDate = currentdate;
    this.checktimeReport();
    this.Checkin_Status();
  }

  Load_Menu(EmpCode, Empname) {
    this.api.loadMenu(EmpCode).then((Menu_res) => {
      if (Menu_res) {
        this.empCodeName = Empname;
        this.api.EmpCode = EmpCode;
        this.empimg = "https://dbasesolutions.in/Emp_ph/" + EmpCode + ".jpg";//"./assets/DBS/Emp_ph/Small/" + EmpCode + ".jpg";
        this.MainMenu = Menu_res;
        this._MainMenu = this.MainMenu;
      }
    })
  }

  // openMenu(fab) {
  //   if (this.menuopen == false) {
  //     this.menu.enable(true);
  //     this.menuopen = true;
  //     this.menu.open();
  //     fab.activated = false;
  //   }
  //   else {
  //     // this.menu.enable(true, 'main-menu');
  //     this.menuopen = false;
  //     this.menu.close();
  //     fab.activated = true;
  //   }

  // }

  openMenu(fab) {
    if (this.menuopen == false) {
      this.menu.enable(true);
      this.menuopen = true;
      this.menu.open();
      // fab.activated = false;
      fab.activated = true;
    }
    else {
      // this.menu.enable(true, 'main-menu');
      this.menuopen = false;
      this.menu.close();
      fab.activated = false;
      // fab.activated = true;
    }
  }

  openFabBack(fab) {
    console.log('Back');
  }
  openFabHome(fab) {
    this.router.navigateByUrl('/home');
  }

 async openFabEprofile(fab) {
  const loader = await this.api.loadingController.create({spinner: 'lines',
  cssClass:'custom-loading', message: 'Loading...'
    });
  loader.present();
    this.router.navigateByUrl('/eprofile');
   loader.dismiss();
  }
  
  openFabSettings(fab) {
    if (this.api.UserDesig == 'Director' || this.api.UserDesig == 'In-Charge F&A') {
      this.router.navigateByUrl('/sources');

    }
    else {

    }
    console.log('Settings1');
  }
  closeFab(fab) {
    let element: HTMLElement = document.getElementById('fabid') as HTMLElement;
    this.closeFab1(element);
  }

  closeFab1(fab) {
    fab.activated = false;
    this.menuopen = false;
  }

  openFablogout(fab) {
    this.menu.enable(false);
    this.api.logOut();
  }

  async TimeReport(ischeckin: boolean, ischeckined: boolean) {
    this.Checkin_Status();
    console.log(ischeckin);
    if (ischeckin) {
      if (!this.Isempinlocations) { this.api.presentToast("Your not in office location..!"); return; }
      if (this.ischeckined) { this.api.presentToast("Time already reported..!"); return; }

      const alert = await this.alertCtrl.create({
        header: 'Do you want to sign in Now?',
        buttons: [
          {
            text: 'No',
            handler: () => {
               return true; }

          },
          {
            text: 'Yes',
            handler: () => {
              let timereport: any = {
                _Rid:1,
                _Emp_Id: this.api.EmpCode,
                _Emp_Name: this.api.EmpName,
                _Report_Staus: "Pending",
                _Time: this.time,
                _Date: ""
              };

              console.log(timereport);
              let headers: any = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
              clearInterval(this.timer);
              this.http.post(this.api.api + "Timings/SubmitEmpReporting", timereport, headers).subscribe((data: any) => {
                clearInterval(this.timer);
                console.log(data);
                this.ischeckined = true;
                this.checktimeReport();
                this.api.presentToast('Time Reported...');
              },
                (error) => {
                  console.log(error);
                  this.api.presentToast(error);
                })
            }
          }
        ]
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
    }
    else {
      this.api.presentToast("Check-In not enabled! Contact administrator..!");
    }
  }
  async checktimeReport() {
    let today = new Date().toLocaleDateString().slice(0, 10).replace(/-/g, '/');
    this.api.SetToday();
    return new Promise((resolve) => {
      this.http.get(`${this.api.api}checkin/CheckEmpReporting?empCode=${this.api.EmpCode}&date=${this.api.today}`).subscribe((dt_chkemp) => {
        if (Object.keys(dt_chkemp).length != 0) {
          this.time = dt_chkemp[0]["Time"];
          this.tClass = dt_chkemp[0]["tclass"];
          this.ischeckined = true;

          console.log(this.ischeckined);
        }
        this.Load_Timer();
      })
    })
  }

  Load_Timer() {
    console.log(this.ischeckined);
    if (!this.ischeckined) {
      this.timer = setInterval(() => {
        this.setData();
      }, 1000);
    }
    else
    {
      clearInterval(this.timer);
    }
  }

  setData() {
    let y = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
    y.setMinutes(y.getMinutes() + 330);
    let time2 = (y.getHours() + ":" + y.getMinutes() + ":" + y.getSeconds());
    this.time = time2;
  }


  // Checkin_Status() {
  //    this.getPosition();
  //     this.api.Checkin_Status(this.api.EmpCode).then((dt_checkin) => {
  //       this.Ischeckin_Enable = dt_checkin[0]["IsChekin_Enable"];
  //       console.log(this.Ischeckin_Enable);
  //     })
  // }

  Checkin_Status() {
    this.getPosition();
    return new Promise((resolve) => {
      this.http.get(`${this.api.api}Checkin/Checkin_Status?Empcode=${this.api.EmpCode}`).subscribe((dt_checkin) => {
        resolve(dt_checkin);
        this.Ischeckin_Enable = dt_checkin[0]["IsChekin_Enable"];
        console.log('checking');
        console.log(this.Ischeckin_Enable);
      })
    })
  }

  getPosition() {
    // let distance = this.api.calDistance("17.722760", "83.322717", "17.7250754", "83.3227782");
    // console.log(Number(distance));
    // if (0.5 >= Number(distance)) {
    //   console.log('Vizag Office')
    // }
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve(resp);
        this.lati = resp.coords.latitude;
        this.long = resp.coords.longitude;
        let distance = this.api.calDistance("16.697321", "81.0878702", this.lati, this.long);
        console.log(distance);
        if (0.5 >= distance) {
          this.Isempinlocations = true;//Eluru Office
        }
        else {
          let distance = this.api.calDistance("17.722760", "83.322717", this.lati, this.long);
          if (0.5 >= distance) {
            this.Isempinlocations = true;//Vizag Office
          }
          else {
            this.Isempinlocations = false;//Not in office location
          }
        }
      },
        err => {
          reject(err);
        });
    });
  }


}