import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EmpAuthGuard } from './emp-auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'leaverequest',
    loadChildren: () => import('./pages/leaverequest/leaverequest.module').then( m => m.LeaverequestPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'transactions/:id',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'adminrequests',
    loadChildren: () => import('./pages/adminrequests/adminrequests.module').then( m => m.AdminrequestsPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'notes',
    loadChildren: () => import('./pages/notes/notes.module').then( m => m.NotesPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'workreport',
    loadChildren: () => import('./pages/workreport/workreport.module').then( m => m.WorkreportPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'adminworkreport',
    loadChildren: () => import('./pages/adminworkreport/adminworkreport.module').then( m => m.AdminworkreportPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'moneytransfer',
    loadChildren: () => import('./pages/moneytransfer/moneytransfer.module').then( m => m.MoneytransferPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'vouchers',
    loadChildren: () => import('./pages/vouchers/vouchers.module').then( m => m.VouchersPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'empadvances',
    loadChildren: () => import('./pages/empadvances/empadvances.module').then( m => m.EmpadvancesPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'invoices',
    loadChildren: () => import('./pages/invoices/invoices.module').then( m => m.InvoicesPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'timings',
    loadChildren: () => import('./pages/timings/timings.module').then( m => m.TimingsPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'timerequests',
    loadChildren: () => import('./pages/timerequests/timerequests.module').then( m => m.TimerequestsPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stock/stock.module').then( m => m.StockPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then( m => m.DemoPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'sources',
    loadChildren: () => import('./pages/sources/sources.module').then( m => m.SourcesPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'eprofile',
    loadChildren: () => import('./pages/eprofile/eprofile.module').then( m => m.EprofilePageModule),
    canActivate:[EmpAuthGuard]
  },   
  {
    path: 'testing',
    loadChildren: () => import('./testing/testing.module').then( m => m.TestingPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'cryreports',
    loadChildren: () => import('./pages/cryreports/cryreports.module').then( m => m.CryreportsPageModule),
    canActivate:[EmpAuthGuard]
  },
  {
    path: 'tickets',
    loadChildren: () => import('./pages/tickets/tickets.module').then( m => m.TicketsPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'equipment',
    loadChildren: () => import('./pages/equipment/equipment.module').then( m => m.EquipmentPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'salaries',
    loadChildren: () => import('./pages/salaries/salaries.module').then( m => m.SalariesPageModule)
  },   {
    path: 'duties',
    loadChildren: () => import('./pages/duties/duties.module').then( m => m.DutiesPageModule)
  },  {
    path: 'clientcontactdetails',
    loadChildren: () => import('./pages/clientcontactdetails/clientcontactdetails.module').then( m => m.ClientcontactdetailsPageModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then( m => m.TasksPageModule)
  },

 
  // {
  //   path: 'tst',component:TstComponent
    
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
