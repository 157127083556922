import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceService } from './servies/service.service';

@Injectable({
  providedIn: 'root'
})
export class EmpAuthGuard implements CanActivate {

  constructor(private api:ServiceService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      for (let i = 0; i < this.api.menu_auth.length; i++) {
        if (this.api.menu_auth[i]["NavigateUrl"].indexOf("/" + route.routeConfig.path.split('/')[0])==0) {
          return true;
        }
      }
      this.api.presentToast("You dont have access!!! Please contact Administrator ");
      return false;
  }
  
}
