import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import{MatButtonModule } from '@angular/material/button';
import{MatInputModule } from '@angular/material/input';
import{MatFormFieldModule } from '@angular/material/form-field';

import{MatTableModule } from '@angular/material/table';
import{MatStepperModule } from '@angular/material/stepper';
import{MatSelectModule } from '@angular/material/select';
import{MatIconModule } from '@angular/material/icon';
import{MatPaginatorModule } from '@angular/material/paginator';
import{MatSortModule } from '@angular/material/sort';
import{MatCheckboxModule } from '@angular/material/checkbox';
import{MatRadioModule } from '@angular/material/radio';
import{MatGridListModule } from '@angular/material/grid-list';
import{MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[MatButtonModule,MatInputModule,MatFormFieldModule,MatTableModule,MatStepperModule,
    MatSelectModule,MatIconModule,MatPaginatorModule,MatSortModule,MatCheckboxModule,MatRadioModule,
    MatGridListModule,MatDatepickerModule,MatNativeDateModule,MatTabsModule,MatExpansionModule,
    MatAutocompleteModule,MatTooltipModule, MatChipsModule]
})
export class MaterialModule { }
