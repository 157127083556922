import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpInterceptor } from '@angular/common/http';
import { promise } from 'protractor';
import { LoadingController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Platform, AlertController } from '@ionic/angular';
import { DatePipe } from '@angular/common'
import { Storage } from '@ionic/storage-angular'
import { format, parseISO, getDate, getMonth, getYear } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
     public api = 'https://dbsapi.dbasesolutions.in/api/';
    public imgpath = 'https://dbsapi.dbasesolutions.in/';

  //public imgpath = 'http://localhost:50765/';
  //public api = 'http://localhost:50765/api/';

  //  public api = 'https://dbsapi.dbasesolutions.in/api/';
  //  public imgpath = 'https://dbsapi.dbasesolutions.in/';

  //public imgpath = 'http://localhost:50765/';
   //public api = 'http://localhost:50765/api/';

  
  public EmpCode: string;
  public checkinstatus = ""; 
  public menu_auth:any;
  public url: boolean;

  employeeReportingsByDate: any = "";
  currentuser: any;
  userLoggedIn = false;
  disableTimeReport: boolean;
  notifyUser: any;
  today: string;
  public EmpCodeName: string;
  loacalStorage: Storage;
  public EmpName: any = "";
  public isChekin = "";
  date: Date;
  public UserType: string;
  public UserDesig: string;
  public opensettings: boolean;
  Ischeckin_Enable: any;
  public P_Incharge:any="";
  constructor(private router: Router, public datepipe: DatePipe,
    public http: HttpClient,
    public toast: ToastController,
    public loadingController: LoadingController,
    public storage: Storage) {
    this.initializeStorage();
    storage.get('username').then((username) => {
      if (username != null) {
        storage.get('password').then((password) => {
          storage.get('IsChekin').then((IsChekin) => { this.isChekin = IsChekin; });
          this.EmpCode = username;
          storage.get('EmpCodeName').then((EmpCodeName) => { this.EmpCodeName = EmpCodeName; });
          storage.get('UserType').then((UserType) => { this.UserType = UserType; });
          // console.log(this.UserType);
          this.EmpName = this.EmpCodeName;
          this.router.navigateByUrl('/home');
        });

      }
    });

    this.opensettings = false;
    if (this.EmpCode == '1501') {
      this.opensettings = true;
    }
  };

  async EmployeeAllReportingsByEmpCode(empCode: string, daymonthyear: string) {
    this.SetToday();
    return new Promise((resolve) => {
      this.http.get(`${this.api}Checkin/LoadAllReportingsById?employeeCode=${this.EmpCode}&daymonthYear=${daymonthyear}`).subscribe((Menu_res1) => {
        //this.employeeReportingsByDate=Menu_res;
        resolve(Menu_res1);
      })
    })
  }

  async All() {

  }

  EmpAuth(url):boolean {
    let isaccess:boolean=false;
    
    for (let i = 0; i < this.menu_auth.length; i++) {
      console.log(this.menu_auth[i]["NavigateUrl"].indexOf("/" + url));
      if (this.menu_auth[i]["NavigateUrl"].indexOf("/" + url)) {
        isaccess=true;
        break;
      }
    }
    return isaccess;
  }

  refresh() {
    // this.router.navigate(['/transactions']);
    this.router.navigateByUrl('/transactions');
  }

  CurrentMY() {
    this.date = new Date();
    return format(parseISO(this.changeformat(this.date.toString(), 'yyyy-MM-yy')), "MMM-yyyy");
  }

  formatDate(value: string, dateformat: string) {
    return format(parseISO(value), dateformat);
  }

  changeformat(change_date: string, format: string) {
    return this.datepipe.transform(change_date, format);
  }

  SetToday() {
    let date = new Date();
    this.today = date.getFullYear() + '-' + (("0" + (date.getMonth() + 1)).slice(-2)) + '-' + (("0" + date.getDate()).slice(-2));
  }

  async initializeStorage() {
    const storageVar = await this.storage.create();
    this.loacalStorage = storageVar;
  }
  setStorage(key, value) {
    this.loacalStorage?.set(key, value);
  }

  async present_loader() {
    return await this.loadingController.create({
      message: 'Logging In..'
    });
  }


  async login(username, password) {
    const loader = await this.loadingController.create({
      message: 'Logging In..'
    });
    loader.present();
    //console.log(`${this.api}login/UserLogin?username=${username}&password=${password}`);
    return new Promise((resolve) => {
      this.http.get(`${this.api}login/UserLogin?username=${username}&password=${password}`).subscribe((res: string[]) => {
        resolve(res);
        this.currentuser = res;
        if (res.length == 1) {
          this.EmpCodeName = '' + this.currentuser.map(t => t.EmpName);
          this.EmpCode = username;
          this.EmpName = this.EmpCodeName;
          this.UserDesig = res[0]["Designation"];
          this.UserType = res[0]["UserType"];
          this.loacalStorage?.set('username', username);
          this.loacalStorage?.set('password', password);
          this.loacalStorage?.set('EmpCodeName', this.EmpCodeName);
          this.loacalStorage?.set('UserType', res[0]["UserType"]);
          this.loacalStorage?.set('UserDesig', res[0]["Designation"]);
          this.loacalStorage?.set('IsChekin', '' + this.currentuser.map(t => t.IsChekin_Enable));
          this.loacalStorage?.set("P_Incharge",res[0]["P_INCHARGE"]);
          this.P_Incharge=res[0]["P_INCHARGE"];
          console.log(this.P_Incharge);

          this.router.navigateByUrl('/home');

        }
        else {

          this.presentToast('Invalid User Credentials....');
        }
        loader.dismiss();
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (err) => {
        console.log(err);
      };
    });
  };

  logOut() {
    this.storage.clear();
    this.userLoggedIn = false;
    this.router.navigateByUrl('/login');
  }

  // async presentToast(msg: string) {
  //   const toast = await this.toast.create({
  //     message: msg,
  //     position: 'top',
  //     duration: 2000
  //   });
  //   toast.present();
  // }

  async presentToast(msg: string) {
    
    const toast = await this.toast.create({
      message: msg,
      position: 'top',
      cssClass: "toaster-styles",
      duration: 2000
    });
    await toast.present();
  } 

  loadMenu(EmpCode) {
    return new Promise((resolve) => {
      this.http.get(`${this.api}login/Load_Menu?Empcode=${EmpCode}`).subscribe((Menu_res) => {
        resolve(Menu_res);
        this.menu_auth=Menu_res;
        console.log(this.menu_auth);
      })
    })
  }

  async sendNotification(notify) {
    let noted = await this.retrieveEmployeeById(notify.SendTo);
    let promise = new Promise((resolve, reject) => {
      let body = {
        "app_id": "9ac11fa0-538b-4857-a1ed-ad4295be3b16",
        "include_player_ids": [this.notifyUser.DeviceId],
        "contents": { "en": `${notify.Body} Notification` },
        "headings": { "en": `${notify.type}` },
      }
      let options = new HttpHeaders().set('Content-Type', 'application/json');
      this.http.post("https://onesignal.com/api/v1/notifications", body, {
        headers: options.set('Authorization', 'Basic ODY3ZDY5MGYtNzAwYy00YzliLWIwNWUtNmQ2MzAyZWQ2Mjgz'),
      }).subscribe((data) => {
        // resolve();
      });
    });
    return promise;
  }

  async retrieveEmployeeById(id) {
    let loader = await this.loadingController.create({
      message: "Loading.."
    });
    loader.present();
    this.notifyUser = await this.http.get(this.api + '/loadEmployee?id=' + id).toPromise();
    loader.dismiss();
    return this.notifyUser;
  }

  async EmployeeReportingsByEmpCodeAndDate(empCode: any, date: any) {
    let loader = await this.loadingController.create({ 
      message: "Loading.."
    });
    loader.present();
    this.employeeReportingsByDate = await this.http.get(this.api + "checkin/LoadEmpReportingsById?employeeCode=" + empCode + "&date=" + date).toPromise();

    loader.dismiss();
    return this.employeeReportingsByDate;
  }

  async EmployeeReportingsByDate(date: any) {
    let loader = await this.loadingController.create({ 
      message: "Loading.."
    });
    loader.present();
    this.http
      .get(this.api + "checkin/LoadTodayEmpReportingsByDate?date=" + date)
      .subscribe((data: any) => {
        this.employeeReportingsByDate = data;
        loader.dismiss();
      },
        (error: any) => {
          console.dir(error);
          loader.dismiss();
        });
  }

  async Admincheckinstatus(rid: string, statustype: string, dates: string, empcode: string) {
    return new Promise((resolve) => {
      this.http.get(`${this.api}checkin/RespondToCheckin?EmpId=${empcode}&Rid=${rid}&date=${dates}&statustype=${statustype}`).subscribe((Menu_res) => {
        resolve(Menu_res);
      })
    })
  }

  calDistance(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  // Checkin_Status(EmpCode) {
  //   console.log(EmpCode);
  //   return new Promise((resolve) => {
  //     this.http.get(`${this.api}Checkin/Checkin_Status?Empcode=${EmpCode}`).subscribe((dt_checkin) => {
  //       resolve(dt_checkin);
  //       this.Ischeckin_Enable = dt_checkin[0]["IsChekin_Enable"];
  //       console.log('checking');
  //     })
  //   })
  // }
 




}
