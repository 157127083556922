import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { DatePipe } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'; 
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MasterComponent } from './master/master.component';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { HashLocationStrategy, LocationStrategy }from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatTabsModule} from '@angular/material/tabs';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DatePickerFormatDirective } from 'src/date-picker-format.directive';
import { MatTimepickerModule } from 'mat-timepicker';  
import {MatButtonToggleModule} from '@angular/material/button-toggle'; 
// import { NgxNewstickerAlbeModule } from 'ngx-newsticker-albe';
@NgModule({
  declarations: [AppComponent,MasterComponent,DatePickerFormatDirective,],
  entryComponents: [],
  imports: [BrowserModule,MatTabsModule, IonicModule.forRoot(), 
    AppRoutingModule,HttpClientModule,IonicStorageModule.forRoot(), 
    BrowserAnimationsModule,MaterialModule,
    ReactiveFormsModule,FormsModule, NgxExtendedPdfViewerModule,MatDatepickerModule,MatMomentDateModule,
    MatTimepickerModule,MatButtonToggleModule,
    // NgxNewstickerAlbeModule
    // NgxMatTimepickerModule.setLocale('en-GB')
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },DatePipe,
     Camera,
     BarcodeScanner,
    Camera,
    BarcodeScanner,
  {provide: LocationStrategy,useClass:HashLocationStrategy},FormControl],
  bootstrap: [AppComponent,MasterComponent],
})
export class AppModule {}
